<template>
  <svg
    id="drop"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        id="drop-bg"
        d="M66.9686 305.882C73.0665 257.388 144.941 135.234 253.703 25.5C342.204 114.752 401.864 168.254 440.5 287C438.227 411.372 350.18 501.889 245.5 505.5C140.82 509.111 60.8708 354.375 66.9686 305.882Z"
        stroke="white"
        :style="{ fill: hover ? '#982525' : 'transparent' }"
      />
      <path
        id="drop-stroke"
        d="M303.486 49.2622C288.949 32.3032 275.867 17.2824 265.691 4.6843C265.207 3.71522 264.238 3.23067 263.753 2.74613C258.423 -1.61475 250.671 -0.645665 246.31 4.6843C236.134 17.2824 223.052 32.3032 208.515 49.2622C144.556 123.397 55.8846 226.12 55.8846 311.884C55.8846 367.122 78.1736 417.03 114.514 453.37C150.855 489.227 200.763 512 256.001 512C311.238 512 361.146 489.711 397.487 453.37C433.828 417.03 456.117 367.122 456.117 311.884C456.117 226.12 367.445 123.397 303.486 49.2622ZM380.043 435.442C348.064 467.422 304.455 487.288 256.001 487.288C207.546 487.288 163.938 467.422 131.958 435.927C100.463 404.432 80.5963 360.338 80.5963 311.884C80.5963 235.326 165.876 136.48 227.413 65.2521C237.588 53.1385 247.279 41.9941 256.001 31.3341C264.722 41.9941 274.413 53.1385 284.589 64.7675C346.125 135.995 431.405 234.357 431.405 311.399C431.405 359.854 411.539 403.463 380.043 435.442Z"
        :style="{ fill: hover ? '#982525' : '#982525' }"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="512" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "drop-icon",
  props: {
    hover: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#drop {
  max-width: 100%;
  max-height: 5rem;

  #drop-stroke {
    transition: fill 0.3s ease-in;
  }

  #drop-bg {
    transition: fill 0.4s ease-in;
  }
}
</style>