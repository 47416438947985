<template>
  <div data-aos-id="records" id="records" class="inner">
    <div id="records-inner"><h1>ZÁZNAMY Z PROBĚHLÝCH WEBINÁŘŮ</h1></div>
    <p>
      Záznamy z webinářů jsou zveřejňovány vždy do třech pracovních dnů od
      on-line přenosu.
    </p>
    <v-card flat>
      <v-tabs show-arrows>
        <v-tab id="first-webinar"> 1. webinář </v-tab>
        <v-tab id="second-webinar"> 2. webinář </v-tab>
        <v-tab id="third-webinar"> 3. webinář </v-tab>
        <v-tab id="fourth-webinar"> 4. webinář </v-tab>
        <v-tab id="fifth-webinar"> 5. webinář </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="video-wrapper_text">
                <h1>Péče o pacienty s hemofilií</h1>
                <p class="authors">
                  Přednášející: MUDr. Peter Salaj, Zuzana Adamovská, <br />
                  prof. MUDr. Cyril Höschl, DrSc., FRCPsych.
                </p>
                <p>
                  Webinář seznamuje posluchače se současným stavem a vyhlídkami
                  do budoucna v oblasti léčby hemofilie. Dále se zabývá
                  specifiky péče o dětské pacienty a v neposlední řadě informuje
                  sestry o tom, jak mohou co nejlépe pečovat o své duševní
                  zdraví a bránit se pracovnímu stresu.
                </p>
              </div>
              <div class="video-wrapper">
                <div>
                  <video
                    data-aos="fade-in"
                    data-aos-duration="5000"
                    width="100%"
                    height="auto"
                    controls
                    poster="../assets/img/video1-thumb.png"
                  >
                    <source
                      src="https://general-storage.b-cdn.net/web-vid1.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="video-wrapper_text">
                <h1>Péče o pacienty s hemofilií</h1>
                <p class="authors">
                  Přednášející: MUDr. Radomíra Hrdličková, Veronika Čepeláková,
                  Mirka Engelová
                </p>
                <p>
                  Webinář se v první své části věnuje tomu, proč je nutné
                  vyšetřovat klouby pacientů ultrazvukem.
                </p>
                <p>
                  Druhým webinářem nás tentokrát provedla MUDr. Radomíra
                  Hrdličková, která nás seznámila s tím, proč je nutné
                  vyšetřovat klouby pacientů ultrazvukem, dále pak zdravotní
                  sestra Veronika Čepeláková, která se zaměřila na domácí léčbu
                  dětí s vrozeným krvácivým onemocněním a na závěr webináře jsme
                  se seznámili s tím, jak aktivně relaxovat s pohybovou
                  specialistkou Mirkou Engelovou.
                </p>
              </div>
              <div class="video-wrapper">
                <div>
                  <video
                    data-aos="fade-in"
                    data-aos-duration="5000"
                    width="100%"
                    height="auto"
                    controls
                    poster="../assets/img/video1-thumb.png"
                  >
                    <source
                      src="https://general-storage.b-cdn.net/web-vid2.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="video-wrapper_text">
                <h1>Péče o pacienty s hemofilií</h1>
                <p class="authors">
                  Přednášející: MUDr. Köhlerová Světlana, Bc. Kateřina
                  Vokšayová, DiS, doc. PhDr. Dr. phil. Laura Janáčková, CSc.
                </p>
                <p>
                  Webinářem Vás tentokrát provázely MUDr. Světlana Köhlerová,
                  která se zaměřila na personalizovanou léčbu pacienta s
                  hemofilií, dále pak zdravotní sestra Bc. Kateřina Vokšayová,
                  DiS a na závěr nám doc. PhDr. Dr. phil. Laura Janáčková, CSc.
                  přiblížila štěstí z pohledu pozitivní psychologie.
                </p>
              </div>
              <div class="video-wrapper">
                <div>
                  <video
                    data-aos="fade-in"
                    data-aos-duration="5000"
                    width="100%"
                    height="auto"
                    controls
                    poster="../assets/img/video1-thumb.png"
                  >
                    <source
                      src="https://general-storage.b-cdn.net/Takeda_SESTRY_webinář_III.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="video-wrapper_text">
                <h1>Péče o pacienty s hemofilií</h1>
                <p class="authors">
                  Přednášející: prim. MUDr. Viera Pohlreichová, Kateřina
                  Altmanová & Martin Bohůn, prof. PhDr. Radek Ptáček, Ph.D.
                </p>
                <p>
                  Webinářem Vás tentokrát provázeli prim. MUDr. Viera
                  Pohlreichová, která se zaměřila na Von Willebrandovu chorobu,
                  dále si převzali slovo předsedové pacientských organizací
                  Kateřina Altmanová & Martin Bohůn a na konci webináře nás svou
                  přednáškou o tom, jak pečovat o své duševní zdraví a nevyhořet
                  potěšil profesor klinické psychologie prof. PhDr. Radek
                  Ptáček, Ph.D.
                </p>
              </div>
              <div class="video-wrapper">
                <div>
                  <video
                    data-aos="fade-in"
                    data-aos-duration="5000"
                    width="100%"
                    height="auto"
                    controls
                    poster="../assets/img/video1-thumb.png"
                  >
                    <source
                      src="https://general-storage.b-cdn.net/Webinář_Zoom_Sestřičky%204.w.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="video-wrapper_text">
                <h1>Péče o pacienty s hemofilií</h1>
                <p class="authors">
                  Přednášející: prim. MUDr. Jaromír Gumulec, Ph.D., Dana
                  Malásková, Ing. Iveta Šperlová
                </p>
                <p>
                  Webinářem Vás tentokrát provázeli prim. MUDr. Jaromír Gumulec,
                  Ph.D., který se zaměřil na získanou hemofilii A, dále si
                  převzala slovo zdravotní sestra Dana Malásková, která nás
                  seznámila s použitím portů u hemofiliků a na konci webináře
                  nás svou přednáškou o tom, jak chytře na emoce potěšila
                  lektorka Ing. Iveta Šperlová.
                </p>
              </div>
              <div class="video-wrapper">
                <div>
                  <video
                    data-aos="fade-in"
                    data-aos-duration="5000"
                    width="100%"
                    height="auto"
                    controls
                    poster="../assets/img/video1-thumb.png"
                  >
                    <source
                      src="https://general-storage.b-cdn.net/Sestry_2.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export default {
  name: "Records",
  mounted() {
    document.getElementById("fifth-webinar").click();
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/scss/variables" as *;

#records {
  padding-bottom: 10rem;
  padding-top: 7.5rem;

  .button-wrapper {
    margin-top: 2rem;

    button {
      margin-right: 2rem;
    }
  }

  .video-wrapper {
    margin-top: 2rem;

    video {
      outline: none;
      cursor: pointer;
    }

    .video-wrapper_text {
      h1 {
        margin-top: 0;
      }

      .authors {
        margin-bottom: 1rem;
        font-size: 1.3rem;
        font-family: ChampagneThick;
        color: rgb(44, 44, 44);
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .video-wrapper {
    video {
      margin-right: 2rem;
    }
  }
}
@media screen and (max-width: $md) {
  #records {
    background: $secondary;
    padding: 1rem;
    padding-top: 3rem;
    margin: 0 -1rem;

    .v-card {
      background: $secondary;
    }

    ::v-deep .theme--light.v-tabs > .v-tabs-bar {
      background-color: $secondary;
    }
    .video-wrapper {
      flex-direction: column;
      margin-top: 0;

      video {
        width: 100%;
        margin: 0;
      }

      .video-wrapper_text {
        max-width: 100%;
        margin-top: 2rem;
      }
    }
  }
}
</style>
