<template>
  <v-container class="landing-page">
    <app-bar
      :active-button-name="activeButtonName"
      @stopWatchingScroll="stopWatchingScroll"
      @nav-icon-click="onNavIconClick"
    />
    <app-drawer v-model="showNavigation" />
    <promo-section />
    <waves />
    <about-project />
    <schedule @clickOnPerson="clickOnPerson" />
    <lecturers :lectorName="lectorName" />
    <records />
    <exercises />
    <page-footer />
    <div class="footer-bg">
      <section>
        <div class="inner">
          <div class="contacts">
            <div class="contact__title">Kontakty</div>
            <div class="contact-sections">
              <div class="section">
                <div class="contact__subtitle">Informace o projektu</div>
                <div class="contact__item">
                  <div class="icon">
                    <v-icon color="white">mdi-email</v-icon>
                  </div>
                  <div>brozkova@jetovkrvi.cz</div>
                </div>
                <div class="contact__item">
                  <div class="icon">
                    <v-icon color="white">mdi-phone</v-icon>
                  </div>
                  <div>+420 770 197 013</div>
                </div>
              </div>
              <div class="section">
                <div class="contact__subtitle">Technická podpora</div>
                <div class="contact__item">
                  <div class="icon">
                    <v-icon color="white">mdi-email</v-icon>
                  </div>
                  <div>podpora@jetovkrvi.cz</div>
                </div>
              </div>
            </div>
          </div>
          <div class="web-pages">
            <div class="web-pages__title">Důležitá webová stránka</div>
            <div class="web-pages__link">
              <a href="https://www.agenturydomacipece.cz/" target="_blank"
                >www.agenturydomacipece.cz</a
              >
            </div>
          </div>
        </div>
        <div class="page-code">C-ANPROM/CZ/HEM/0010</div>
      </section>
    </div>
  </v-container>
</template>

<script>
import AboutProject from "../components/AboutProject.vue";
import AppBar from "../components/AppBar.vue";
import Lecturers from "../components/Lecturers.vue";
import PromoSection from "../components/PromoSection.vue";
import Records from "../components/Records.vue";
import Schedule from "../components/Schedule.vue";
import Waves from "../components/Waves.vue";
import PageFooter from "../components/PageFooter.vue";
import { elementInViewport } from "../utils/elementInViewport";
import AppDrawer from "../components/AppDrawer.vue";
import Exercises from '../components/Exercises.vue';

export default {
  name: "LandingPage",
  components: {
    AppBar,
    PromoSection,
    Waves,
    AboutProject,
    Schedule,
    Lecturers,
    Records,
    PageFooter,
    AppDrawer,
    Exercises,
  },
  data() {
    return {
      blockId: [
        "promo",
        "about",
        "schledule-header",
        "lecture-inner",
        "records-inner",
        "exercises-inner",
      ],
      activeButtonName: null,
      watchScroll: true,
      showNavigation: false,
      lectorName: null,
    };
  },
  created() {
    document.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$nextTick(() => {
      if(this.$route.name === "zaznamy") {
        const el = document.getElementById('records');
        el.scrollIntoView();
      }
    });
  },
  methods: {
    stopWatchingScroll(value) {
      this.watchScroll = !value;
    },
    handleScroll() {
      if (this.watchScroll) {
        this.blockId.find((id) => {
          const el = document.getElementById(id);

          if (elementInViewport(el)) {
            this.activeButtonName = id;
          }
        });
      }
    },
    onNavIconClick() {
      this.showNavigation = !this.showNavigation;
    },
    clickOnPerson(value) {
      this.lectorName = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../assets/scss/variables.scss' as *;

.footer-bg {
  background: #2a2a2a;
  width: 100%;
  height: 17rem;
  position: absolute;
  left: 0;
  bottom: 0;
}

section {
  padding: 2rem 1rem;

  .inner {
    display: flex;
    justify-content: space-between;
  }

  .contacts {
    margin-right: 4rem;

    .contact-sections {
      display: flex;

      & .section:last-child {
        margin-left: 5rem;
      }
    }

    .contact__title {
      color: white;
      font-size: 2rem;
    }
    .contact__subtitle {
      margin: 1rem 0;
      color: gray;
    }
    .contact__item {
      display: flex;
      color: white;

      .icon {
        margin-right: 1rem;
      }
    }
  }

  .web-pages__title {
    color: white;
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  .web-pages__link {
    color: white;
  }
}

.page-code {
  width: 100%;
  padding-right: 2rem;
  text-align: right;
  color: white;
  font-size: 0.8rem;
}

@media screen and (max-width: $lg) {
  section .contacts .contact__title,
  section .web-pages__title {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: $md) {
  .footer-bg {
    height: 35rem;
  }
  section .contacts {
    margin: 0;
  }

  .inner {
    flex-direction: column;
    align-items: center;
  }

  section .contacts .contact-sections .section:last-child {
    margin-left: 0;
  }

  .contact-sections {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  section .contacts .contact__title,
  section .web-pages__title {
    font-size: 1.5rem;
  }

  .page-code {
    margin-top: 4rem;
  }
}

@media screen and (max-width: $sm) {
  section .contacts .contact__title,
  section .web-pages__title {
    font-size: 1.2rem;
  }
  .footer-bg {
    height: 30rem;
  }
}
</style>