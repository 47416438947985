<template>
<div>
</div>
</template>

<script>
export default {
    mounted() {
        window.location = 'https://us02web.zoom.us/j/81773628415?pwd=N0UwanNXZXcxdXZrV2RpY3M0Nng4dz09';
    }
}
</script>