<template>
  <div id="promo" class="inner-promo">
    <div class="promo__image">
      <header-image />
    </div>
    <div class="promo__text">
      <h1>
        AKADEMIE PÉČE O PACIENTY <br />
        S HEMOFILIÍ
      </h1>
      <p>
        Zdravotní sestry hrají klíčovou roli v péči o pacienty s hemofilií. Jsou
        nejenom odbornými zdravotními pracovníky, kteří svou činností přispívají
        ke zlepšení zdravotního stavu pacienta, ale zároveň se podílejí i na
        edukaci pacientů a jejich rodin. Naším cílem je poskytnout sestřičkám
        možnost kvalitního bezplatného vzdělávání a dále je informovat o tom, jak
        co nejlépe pečovat o své duševní zdraví či jak se vypořádat se stresem
        spojeným s jejich povoláním.
      </p>
      <div class="d-flex mt-8">
        <v-btn depressed rounded @click="scrollTo('about')">o projektu</v-btn>
        <v-btn depressed class="ml-4" rounded @click="scrollTo('schedule')"
          >program</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import HeaderImage from "./HeaderImage.vue";
export default {
  components: { HeaderImage },
  methods: {
    scrollTo(section) {
      const element = document.getElementById(section);
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../assets/scss/variables.scss' as *;

#promo {
  display: flex;
  margin: 10rem auto 30rem auto;

  &.inner-promo {
    max-width: 80rem;
  }

  h1 {
    color: black;
  }

  .promo__image {
    img {
      width: 100%;
    }
  }

  .promo__text {
    margin-left: 8rem;
  }
}

@media screen and (max-width: $lg) {
  #promo {
    flex-direction: column;
    margin-top: 5rem;

    .promo__image {
      text-align: center;
      margin-bottom: 2rem;
    }

    .promo__text {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  #promo {
    margin-top: 0;
  }
}
</style>