<template>
  <v-navigation-drawer
    v-bind="$attrs"
    @input="$emit('input', $event)"
    right
    fixed
    temporary
  >
    <v-list nav dense>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-title @click="scrollTo('top')">úvod</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title @click="scrollTo('about')"
            >o projektu</v-list-item-title
          >
        </v-list-item>

        <v-list-item>
          <v-list-item-title @click="scrollTo('schedule')"
            >program</v-list-item-title
          >
        </v-list-item>

        <v-list-item>
          <v-list-item-title @click="scrollTo('lectures')"
            >lektoři</v-list-item-title
          >
        </v-list-item>

        <v-list-item>
          <v-list-item-title @click="scrollTo('records')"
            >záznamy</v-list-item-title
          >
        </v-list-item>

        <v-list-item>
          <v-list-item-title @click="scrollTo('exercises')"
            >cviky</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          href="https://docs.google.com/forms/d/1um_CAHp5gY2kChDZNHdBIdjisyjUoKpQ7Ug88kwHP5E/edit"
          target="_blank"
        >
          <v-list-item-title>registrace</v-list-item-title>
        </v-list-item>

        <v-list-item @click="openZoomMeeting">
          <v-list-item-title>online přenos</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "app-drawer",
  props: {
    toggleDrawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    drawer() {
      return this.toggleDrawer;
    },
  },
  methods: {
    scrollTo(section) {
      if (section !== "top") {
        const element = document.getElementById(section);
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    openZoomMeeting() {
      open(
        "https://us02web.zoom.us/j/82501865777"
      );
      // let routeData = this.$router.resolve({
      //   name: "online-prenos",
      // });
      // window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/scss/variables" as *;

.v-navigation-drawer {
  z-index: 1000 !important;
  padding-top: 4rem;
}

::v-deep .v-item--active {
  color: $primary !important;
}

a {
  text-decoration: none;
}
</style>
