<template>
  <div data-aos="fade-right" id="about" class="inner">
    <h1>O PROJEKTU</h1>
    <v-card flat>
      <v-tabs show-arrows :vertical="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg">
        <v-tab> Pro koho je projekt určen</v-tab>
        <v-tab> Naše vize </v-tab>
        <v-tab> Náplň programu </v-tab>
        <v-tab> Na koho se obrátit </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Projekt je určen primárně pro sestry, které se zabývají péčí o
                pacienty s hemofilií. Programu se však mohou bezplatně účastnit
                i zdravotníci s jiným zaměřením.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Zdravotní sestry jsou pacientům mnohdy blíže, než samotní lékaři
                a prožívají s nimi důsledky vyplývající z povahy jejich
                onemocnění. Proto je právě duševní pohoda a edukace sestrer
                důležitým faktorem, který může hrát roli ve zlepšování
                zdravotního stavu pacientů. Naším cílem je přinášet sestřičkám
                kvalitní volně dostupné vzdělávání, které bude reflektovat
                jejich reálné potřeby v oblasti edukace.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Program byl primárně zaměřen na edukaci sester pečujících o
                pacienty s hemofiliií. Přednášky však budou postupně zahrnovat i
                témata pro sestry jiných oborů. Každý z webinářů je složen z
                několika krátkých přednášek. Do programu jsou jako lektoři
                zapojeni lékaři, sestry, fyzioterapeuti či psychologové.
                Jednotlivé přednášky jsou garantovány Českou asociací sester a
                Centrem pro trombózu a hemostázu ÚHKT.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                V případě dotazů ohledně odborného programu nás neváhejte
                kontaktovat na brozkova@jetovkrvi.cz. Zároveň velice uvítáme
                jakékoliv náměty na témata nadcházejících přednášek. V případě
                technických problémů či jiných otázek se nám prosím ozvěte na
                podpora@jetovkrvi.cz
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export default {};
</script>

<style lang="scss" scoped>
@use '../assets/scss/variables.scss' as *;

#about {
  padding-top: 7.5rem;
}

@media screen and (max-width: $md) {
  #about {
    padding-top: 1rem;
  }
}

@media screen and (max-width: $sm) {
  #about {
    padding-top: 7rem;
  }
}
</style>