<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    if(window.navigator && navigator.serviceWorker) {
      console.log('Fetching registered service workers...')
      navigator.serviceWorker.getRegistrations().then( function(registrations) { 
        for(let registration of registrations) { 
            console.log("Removing registration...");
            registration.unregister().then(function(success){
              console.log(`The unregistration completed with the status: ${success}`);
            }); 
          } 
      });
    }
  }
};
</script>

<style lang="scss">
@import "./assets/scss/common.scss";
#zmmtg-root {
  display: none;
}

.v-application--wrap {
  .container {
    padding-bottom: 0;
  }
}
</style>
