<template>
  <v-app-bar :elevate-on-scroll="true" fixed color="white">
    <div class="header">
      <div class="header__logo">
        <img src="../assets/img/web_logo.png" alt="" /> jetovkrvi.cz
      </div>
      <v-app-bar-nav-icon
        v-if="!$vuetify.breakpoint.xl && !$vuetify.breakpoint.lg"
        @click="$emit('nav-icon-click');"
      ></v-app-bar-nav-icon>
      <div
        v-if="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg"
        class="header__btns"
      >
        <v-btn-toggle v-model="toggleButton" group color="primary" dense>
          <v-btn activ depressed @click="scrollTo('top')">úvod</v-btn>
          <v-btn activ depressed @click="scrollTo('about')">o projektu</v-btn>
          <v-btn depressed @click="scrollTo('schedule')">program</v-btn>
          <v-btn depressed @click="scrollTo('lectures')">lektoři</v-btn>
          <v-btn depressed @click="scrollTo('records')">záznamy</v-btn>
          <v-btn depressed @click="scrollTo('exercises')">cviky</v-btn>
          <v-btn
            depressed
            href="https://docs.google.com/forms/d/1um_CAHp5gY2kChDZNHdBIdjisyjUoKpQ7Ug88kwHP5E/edit"
            target="_blank"
            >registrace</v-btn
          >
          <v-btn depressed @click="openZoomMeeting">online přenos</v-btn>
        </v-btn-toggle>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  props: {
    activeButtonName: {
      type: String,
    },
  },
  data() {
    return {
      toggleButton: null,
      watchScroll: true,
      drawer: false
    };
  },
  watch: {
    activeButtonName() {
      switch (this.activeButtonName) {
        case "promo":
          this.toggleButton = 0;
          break;
        case "about":
          this.toggleButton = 1;
          break;
        case "schledule-header":
          this.toggleButton = 2;
          break;
        case "lecture-inner":
          this.toggleButton = 3;
          break;
        case "records-inner":
          this.toggleButton = 4;
          break;
        case "exercises-inner":
          this.toggleButton = 5;
          break;
      }
    },
  },
  methods: {
    watchStopScrolling(isScrolling) {
      this.$emit("stopWatchingScroll", true);
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);
      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(() => {
        // Run the callback
        this.$emit("stopWatchingScroll", false);
      }, 1000);
    },
    scrollTo(section) {
      if (section !== "top") {
        const element = document.getElementById(section);
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }

      let isScrolling;
      window.addEventListener(
        "scroll",
        this.watchStopScrolling(isScrolling),
        false
      );
    },
    openZoomMeeting() {
      open('https://us02web.zoom.us/j/82501865777');
      // let routeData = this.$router.resolve({
      //   name: "online-prenos",
      // });
      // window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.v-app-bar {
  z-index: 10000 !important;
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.2) !important;

  a {
    text-decoration: none;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .header__logo {
      img {
        max-width: 1.5rem;
        margin-right: 0.5rem;
      }
    }

    .header__btns {
      .v-btn {
        margin-left: 1rem;
        background: transparent;
        border-radius: 0.3rem;
      }

      .v-btn-toggle {
        border-radius: 0.3rem;
      }
    }
  }
}
</style>
