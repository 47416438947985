<template>
  <div
    data-aos="fade-up"
    data-aos-delay="600"
    data-aos-duration="4000"
    class="page-footer"
  >
    <div class="partners inner">
      <div class="partner">
        <div class="partner__title">Odborný garant projektu</div>
        <a href="https://www.uhkt.cz/" target="_blank"
          ><img src="../assets/img/uhkt.png" alt=""
        /></a>
        <small>Centrum pro trombózu a hemostázu</small>
      </div>
      <div class="partner">
        <div class="partner__title">Partner projektu</div>
        <a href="https://www.takeda.cz" target="_blank"
          ><img src="../assets/img/takeda.jpg" alt=""
        /></a>
      </div>
      <div class="partner">
        <div class="partner__title">Odborný garant přednášek</div>
        <a href="https://www.cnna.cz/" target="_blank"
          ><div class="small-logo">
            <img src="../assets/img/logo_CAS.jpeg" alt="" /></div
        ></a>
      </div>
    </div>

  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export default {};
</script>

<style lang="scss" scoped>
@use "../assets/scss/variables" as *;
.page-footer {
  position: relative;
  z-index: 1;
  padding-bottom: 15rem;
  
  .partners {
    border-top: 1px solid $primary;
    padding: 5rem 0;
    display: flex;
    justify-content: space-around;
    margin-bottom: 5rem;
    .partner {
      max-width: 18rem;
      cursor: pointer;

      .partner__title {
        color: $primary;
        margin-bottom: 2rem;
        text-align: center;
      }

      img {
        width: 100%;
      }

      small {
        margin-top: 2rem;
        display: block;
        color: gray;
        text-align: center;
      }

      .small-logo {
        max-width: 10rem;
        margin: auto;
      }
    }
  }

  section {
    margin-top: 4rem;
    padding: 2rem 0;

    .inner {
      display: flex;
      justify-content: space-between;
    }

    .contacts {
      margin-right: 4rem;

      .contact-sections {
        display: flex;

        & .section:last-child {
          margin-left: 5rem;
        }
      }

      .contact__title {
        color: white;
        font-size: 2rem;
      }
      .contact__subtitle {
        margin: 1rem 0;
        color: gray;
      }
      .contact__item {
        display: flex;
        color: white;

        .icon {
          margin-right: 1rem;
        }
      }
    }

    .web-pages__title {
      color: white;
      margin-bottom: 1rem;
      font-size: 2rem;
    }

    .web-pages__link {
      color: white;
    }
  }

  .page-code {
    width: 100%;
    padding-right: 2rem;
    text-align: right;
    color: white;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: $lg) {
  .page-footer {
    font-size: 1.2rem;
    section .contacts .contact__title,
    section .web-pages__title {
      font-size: 1.5rem;
    }
    .page-code {
      margin-top: 2rem;
    }
  }
}
@media screen and (max-width: $md) {
  .page-footer {
    font-size: 1.2rem;
    padding-bottom: 30rem;

    .partners {
      flex-direction: column;
      align-items: center;

      .partner {
        margin-bottom: 2rem;
      }
    }

    section .contacts {
      margin: 0;
    }

    .inner {
      flex-direction: column;
      align-items: center;
    }

    section .contacts .contact-sections .section:last-child {
      margin-left: 0;
    }

    .contact-sections {
      flex-direction: column;
      margin-bottom: 2rem;
    }

    section .contacts .contact__title,
    section .web-pages__title {
      font-size: 1.5rem;
    }

    .page-code {
      margin-top: 4rem;
    }
  }
}

@media screen and (max-width: $sm) {
  .page-footer {
    font-size: 1rem;
    section .contacts .contact__title,
    section .web-pages__title {
      font-size: 1.2rem;
    }
  }
}
</style>