<template>
  <div data-aos="fade-left" data-aos-delay="250" id="lectures" class="inner">
    <h1 id="lecture-inner">LEKTOŘI</h1>
    <p>
      Lektory pro webináře volíme vždy z řad těch nejkvalifikovanějších
      odborníků na dané téma. Jedná se o lékaře, zdravotní sestry či psychology
      a fyzioterapeuty.
    </p>

    <v-card flat class="lectures-tabs">
      <v-avatar class="profile" color="grey" size="200" rounded>
        <v-img :src="require(`../assets/img/${profileImage}.jpg`)"></v-img>
      </v-avatar>
      <v-tabs
        show-arrows
        :vertical="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg"
      >
        <v-tab id="tab1" @click="profileImage = 'Kohlerova'">
          MUDr. Světlana Köhlerová
        </v-tab>
        <v-tab id="tab2" @click="profileImage = 'Voksayova'">
          Bc. Kateřina Vokšayová, DiS.
        </v-tab>
        <v-tab id="tab3" @click="profileImage = 'Janackova'">
          doc. PhDr. Dr.phil. Laura Janáčková, CSc.
        </v-tab>
        <v-tab id="tab4" @click="profileImage = 'Hrdlickova'">
          MUDr. Radomíra Hrdličková
        </v-tab>
        <v-tab id="tab5" @click="profileImage = 'Cepelakova'">
          Veronika Čepeláková
        </v-tab>
        <v-tab id="tab6" @click="profileImage = 'Engelova'">
          Mirka Engelová
        </v-tab>
        <v-tab id="tab7" @click="profileImage = 'Salaj'">
          MUDr. Peter Salaj
        </v-tab>
        <v-tab id="tab8" @click="profileImage = 'Hosch'">
          prof. MUDr. Cyril Höschl, DrSc., FRCPsych.</v-tab
        >
        <v-tab id="tab9" @click="profileImage = 'Adamovska'">
          Zuzana Adamovská
        </v-tab>
        <v-tab id="tab10" @click="profileImage = 'Pohlreichova'">
          prim. MUDr. Viera Pohlreichová
        </v-tab>
        <v-tab id="tab11" @click="profileImage = 'Altmanova'">
          Kateřina Altmanová
        </v-tab>
        <v-tab id="tab12" @click="profileImage = 'Bohun'"> Martin Bohůn </v-tab>
        <v-tab id="tab13" @click="profileImage = 'Ptacek'">
          prof. PhDr. Radek Ptáček, Ph.D., MBA
        </v-tab>
        <v-tab id="tab14" @click="profileImage = 'Malaskova'">
          Dana Malásková
        </v-tab>
        <v-tab id="tab15" @click="profileImage = 'Gumulec'">
          prim. MUDr. Jaromír Gumulec, Ph.D.
        </v-tab>
        <v-tab id="tab16" @click="profileImage = 'Sperlova'">
          Ing. Iveta Šperlová
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Absolvovala Lékařskou fakultu Univerzity J. E. Purkyně v Brně v
                oboru pediatrie, má atestaci z pediatrie a také hematologie a
                transfuzního lékařství.
              </p>
              <p>
                Profesně začínala na Dětském oddělení Nemocnice Boskovice, kde
                působila celkem 11 let. Od roku 2000 pracuje ve Fakultní
                nemocnici Brno, kde od roku 2010 působí jako vedoucí lékařka
                ambulance Oddělení dětské hematologie a biochemie.
              </p>
              <p class="mb-0">
                MUDr. Světlana Köhlerová má bohaté zkušenosti z mnoha tuzemských
                a zahraničních rekondičních pobytů pro děti s hemofilií, kterých
                se pravidelně jako lékařka účastnila již od roku 2001. Ze svých
                dlouholetých zkušeností čerpá na hematologických či
                hemofilických konferencích, na kterých pravidelně přednáší.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Absolvovala obor Diplomovaná sestra pro intenzivní péči na Vyšší
                odborné škole zdravotnické a Střední škole zdravotnické Ústí nad
                Labem a bakalářský studijní program Ošetřovatelství na Fakultě
                zdravotnických studií Univerzity Jana Evangelisty Purkyně v Ústí
                nad Labem.
              </p>
              <p>
                Profesní dráhu zdravotní sestry zahájila v Masarykově nemocnici
                v Ústí nad Labem, kde postupně vystřídala dvě oddělení JIP
                –neurochirurgické a kardiologické. V současné době pracuje jako
                zdravotní sestra v Hematologické ambulanci tamtéž a zároveň je
                data manažerkou pro registr Českého národního hemofilického
                programu a České myelomové skupiny.
              </p>
              <p>
                Školí laiky v poskytování první pomoci a šíří osvětu o nutnosti
                jejího včasného poskytnutí.
              </p>
              <p>
                Podílela se na znovuzaložení Místní skupiny Českého červeného
                kříže Ústí nad Labem.
              </p>
              <p class="mb-0">
                Ve volném čase se věnuje zahradě, fence zlatého retrívra, jezdí
                na in-line bruslích a účastní se amatérských běžeckých závodů.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Je docentkou klinické psychologie a zakladatelkou oddělení
                somatopsychiky VFN v Praze. Dlouhodobě se zabývá psychologií v
                oblasti aplikované medicíny, především psychologií u
                onkologických a jinak těžce nemocných pacientů. Je ředitelkou
                Institutu partnerských vztahů a prorektorkou Vysoké školy
                aplikované psychologie. Publikovala 20 monografií věnovaných
                chronickým onemocněním, bolesti a rodinným vztahům.
              </p>
              <p class="mb-0">
                Je autorkou desítek kapitol v odborných monografiích a velkého
                množství odborných a populárních článků.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Absolvovala Lékařskou fakultu Univerzity Palackého v Olomouci,
                má atestaci z vnitřního lékařství I. stupně a také hematologie a
                transfuzní služby.
              </p>
              <p class="mb-0">
                Profesně začínala na interně Městské nemocnice Ostrava, kde
                působila šest let. Od roku 2004 pracuje v Krevním centru
                ostravské Fakultní nemocnice. Zde se jako vedoucí lékařka
                léčebného úseku věnuje zejména pacientům s vrozenou poruchou
                krevní srážlivosti a léčebným hemaferézám.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Absolventka Střední zdravotnické školy v Praze – obor dětská
                sestra – a pomaturitního specializačního studia Ošetřovatelská
                péče o děti a dorost v Brně. V letech 1990–2004 pracovala na II.
                dětské klinice v pražské Fakultní nemocnici Motol, od roku 2004
                na Klinice dětské hematologie a onkologie tamtéž.
              </p>
              <p>
                Je členkou České hematologické společnosti ČLS JEP, v jejímž
                rámci také přednáší na odborných akcích Českého národního
                hemofilického programu. Je spoluautorkou publikace Příručka
                (nejen) pro děti a dospívající s hemofilií a mnoha článků v
                odborných časopisech.
              </p>
              <p class="mb-0">
                S pacientskými organizacemi Český svaz hemofiliků a Hemojunior
                se v letech 1991–2019 jako zdravotník zúčastnila dětských
                rekondičních pobytů pro klienty s krvácivým onemocněním.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Mirka Engelová je pohybová specialistka se zaměřením na celkové
                držení těla. Pracuje především s klienty, kteří trpí bolestmi
                zad a také s ženami během těhotenství i po porodu. Základním
                pilířem cvičení je práce s pánevním dnem, diastázou a dechem.
              </p>
              <p>
                Jako bývalá závodní cyklistka, tanečnice a současně nadšená
                lezkyně ví dobře, jak je důležité postupovat od malých krůčků
                dál.
              </p>
              <p class="mb-0">
                Profesí je zdravotní sestra, masérka, výživová poradkyně,
                trenérka zdravotního cvičení a instruktorka pilates. Fascinují
                ji svaly, šlachy, fascie a její velkou předností je dotek. Díky
                němu je schopna lidské tělo vnímat do nejjemnějších detailů.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Absolvoval 1. lékařskou fakultu v Praze, atestován je v oboru
                hematologie a transfuzní služba.
              </p>
              <p>
                Dlouhodobě působil v Ústavu hematologie a krevní transfuze v
                Praze jako vedoucí lékař Centra pro trombózu a hemostázu a
                současně v centru pro léčbu poruch srážení krve T&H CENTRUM,
                s.r.o. Mimo to je 2. místopředsedou výboru České společnosti pro
                trombózu a hemostázu ČLS JEP.
              </p>

              <p class="mb-0">
                Pan doktor Salaj má na svém kontě bohatou publikační činnost v
                českých i zahraničních odborných periodikách a je považován za
                předního českého odborníka na hemofilii.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Český psychiatr, popularizátor vědy a vysokoškolský pedagog.
                Působí jako ředitel Národního ústavu duševního zdraví (dříve
                Psychiatrické centrum Praha).
              </p>

              <p class="mb-0">
                Po sametové revoluci byl prvním svobodně zvoleným děkanem 3.
                lékařské fakulty Univerzity Karlovy (1990–1997). V letech
                1990–2020 byl přednostou Psychiatrické kliniky 3. LF UK v Praze.
                V letech 2007–2008 zastával post prezidenta Evropské
                psychiatrické asociace (EPA) a v období 2008–2009 byl
                prezidentem Federace evropských lékařských akademií (FEAM). Je
                mj. laureátem ocenění Česká hlava (2008).
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Téměř celý svůj profesní život zasvětila práci ve Fakultní
                nemocnici Ostrava. Působila na všech odděleních Kliniky dětského
                lékařství, ovšem své pravé místo objevila až při péči o děti s
                poruchami krvetvorby. Nyní již téměř tři roky pracuje v
                ambulantní části Oddělení dětské hematologie a hematoonkologie.
              </p>
              <p class="mb-0">
                Paní Zuzana Adamovská se nadále věnuje intervenční péči, ke
                které ji dovedly zkušenosti s maminkami těžce nemocných dětí,
                nebo tzv. peer podpoře, jež je zaměřena na spolupracovníky v
                krizových nadlimitních situacích.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Absolvovala Lékařskou fakultu, obor Vnitřní lékařství. V
                současné době působí na pozici primářky Centra pro trombózu a
                hemostázu v Ústavu hematologie a krevní transfuze v Praze.
                Centrum je zaměřeno na diagnostiku a terapii poruch krevní
                srážlivosti, jeho důležitou činností je péče o dospělé pacienty
                s hemofilií A a B a von Willebrandovou chorobou.
              </p>
              <p class="mb-0">
                Prim. MUDr. Pohlreichová se vedle medicínské praxe věnuje také
                publikační činnosti: je autorkou e-learningových kurzů i
                odborných článků včetně textů publikovaných v zahraničních
                impaktovaných periodikách, aktivně přednáší na kongresech a
                dalších oborových akcích.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Absolvovala maturitní obor Všeobecná sestra, od roku 2009 je
                členkou Českého svazu hemofiliků. V roce 2019 se stala
                předsedkyní pacientské organizace Hemojunior, z. s., kterou je
                dosud. Toto sdružení již dvě desítky let aktivně pomáhá dětem s
                hemofilií a jejich rodinám. Sama paní Altmanová je matkou
                17letého chlapce s hemofilií.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Od roku 2017 je předsedou Českého svazu hemofiliků. Několik let
                působil jako hlavní vedoucí letních táborů pro děti s hemofilií,
                ve volném čase se kromě cestování a správy webových stránek
                věnuje i dalším aktivitám spojeným s ČSH (rekondiční pobyty
                dospělých, workshopy apod.).
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                První profesor lékařské psychologie v České republice, klinický
                psycholog. Dlouhodobě se zabývá nejširší problematikou lékařské
                psychologie a propojování psychologických a medicínských
                poznatků. Působí na Psychiatrické klinice 1. LF UK a VFN v
                Praze. Publikoval více než 150 původních odborných prací v
                domácích i zahraničních odborných časopisech, které dosáhly více
                než 1000 citací. Je řešitelem nebo spoluřešitelem řady vědeckých
                grantů i projektů v sociální oblasti. Je členem redakčních rad
                několika významných zahraničních časopisů s IF.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Vystudovala Střední zdravotnickou školu v Brně. Po absolutoriu
                nastoupila jako zdravotní sestra do Fakultní nemocnice Brno, kde
                v průběhu let sbírala cenné zkušenosti hned na několika
                pracovištích: začínala na popáleninovém oddělení JIP, poté na
                chirurgickém oddělení a od roku 2007 do současnosti se věnuje
                dětským pacientům na Oddělení dětské hematologie a biochemie.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Absolvoval Lékařskou fakultu Univerzity Palackého v Olomouci,
                obor Všeobecné lékařství. Jeho specializační atestací je
                hematologie a transfuzní služba, dlouhodobě se v této oblasti
                věnuje tématům hemostázy a trombóz.
              </p>
              <p>
                V letech 2007–2012 byl přednostou Ústavu klinické hematologie
                Fakultní nemocnice Ostrava. Během tohoto období se Ústav stal
                největším a nejvýznamnějším oborovým pracovištěm v kraji.
              </p>
              <p>
                V současné době zastává post primáře Kliniky hematoonkologie FN
                Ostrava.
              </p>
              <p>
                Zároveň je jedním z hlavních organizátorů každoroční konference
                Pařízkovy dny pořádané Českou společností pro trombózu a
                hemostázu ČLS JEP.
              </p>
              <p>
                V neposlední řadě má na svém kontě mnoho odborných článků vč.
                spoluautorství na klinickém výzkumu zaměřeném na rozbor trombů z
                mozkových tepen pacientů s ICHS, jehož výsledky byly publikovány
                v americkém periodiku Stroke v roce 2020.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Iveta Šperlová je seniorní konzultantka a trenérka s dlouholetou
                praxí na manažerské pozici. Ve své trenérské a konzultační
                činnosti využívá více než 25 let manažerských, obchodních i
                trenérských zkušeností a klade důraz na praktické, konkrétní
                tipy.
              </p>
              <p>
                Jako konzultantka se zaměřuje na zvládání stresu, posilování
                osobní odolnosti, krizové řízení a řešení náročných situací,
                komplexní manažerské a komunikační dovednosti, prezentační
                dovednosti, vyjednávání či podporu týmové spolupráce. Je
                zkušenou lektorkou v dlouhodobých rozvojových programech napříč
                sektory, včetně zdravotnictví. Školí soft skills témata v
                programu Master of Healthcare Administration (MHA) a jako
                konzultantka se podílí na rozvojových aktivitách pro několik
                farmaceutických společností.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export default {
  props: {
    lectorName: {
      type: String,
    },
  },
  data() {
    return {
      profileImage: "Kohlerova",
    };
  },
  watch: {
    lectorName() {
      switch (this.lectorName) {
        case "Kohlerova":
          document.getElementById("tab1").click();
          break;
        case "Voksayova":
          document.getElementById("tab2").click();
          break;
        case "Janackova":
          document.getElementById("tab3").click();
          break;
        case "Hrdlickova":
          document.getElementById("tab4").click();
          break;
        case "Cepelakova":
          document.getElementById("tab5").click();
          break;
        case "Engelova":
          document.getElementById("tab6").click();
          break;
        case "Salaj":
          document.getElementById("tab7").click();
          break;
        case "Hoschl":
          document.getElementById("tab8").click();
          break;
        case "Adamovska":
          document.getElementById("tab9").click();
          break;
        case "Pohlreichova":
          document.getElementById("tab10").click();
          break;
        case "Altmanova":
          document.getElementById("tab11").click();
          break;
        case "Bohun":
          document.getElementById("tab12").click();
          break;
        case "Ptacek":
          document.getElementById("tab13").click();
          break;
        case "Malaskova":
          document.getElementById("tab14").click();
          break;
        case "Gumulec":
          document.getElementById("tab15").click();
          break;
        case "Sperlova":
          document.getElementById("tab16").click();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/scss/variables.scss" as *;

#lectures {
  margin-bottom: 8rem;
  padding-top: 7rem;
  margin-top: 20rem;

  .lectures-tabs {
    margin-top: 5rem;
    display: flex;

    .v-avatar {
      margin-right: 2rem;

      ::v-deep .v-image__image {
        transition: background-image 0.4s ease-in-out;
      }
    }
  }

  .v-tab {
    justify-content: flex-start;
    max-width: 100%;
  }
  ::v-deep .v-tabs-bar {
    margin-right: 3rem;
  }
}

@media screen and (max-width: $md) {
  #lectures {
    padding-top: 4rem;
    margin-top: 0rem;

    ::v-deep .v-tabs-bar {
      width: 100%;
    }

    .v-avatar {
      display: none;
    }
  }
}
</style>
