export function elementInViewport(el) {
    var top = el.offsetTop;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
    }

    return (
        top >= window.pageYOffset &&
        top + height <= window.pageYOffset + window.innerHeight
    );
}

