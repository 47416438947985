import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../views/LandingPage.vue";
import ZoomMeeting from "../views/ZoomMeeting";
import Prenos from "../views/Prenos";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage
  },
  {
    path: "/prenos",
    name: "prenos",
    component: Prenos
  },
  {
    path: "/zaznamy",
    name: "zaznamy",
    component: LandingPage
  },
  {
    path: "/online-prenos",
    name: "online-prenos",
    component: ZoomMeeting
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
