<template>
  <main>
    <div class="btn-back" @click="$router.push({ name: 'LandingPage' })">
      <img src="../assets/img/arrow-left-icon.png" class="icon" /> Zpět na
      úvodní stránku
    </div>
    <div class="webinar-card">
      <h1>Akademie pro sestry: <br> péče o pacienty s hemofilií</h1>
      <p>
        Webinář seznamuje posluchače se současným stavem a vyhlídkami do
        budoucna v oblasti léčby hemofilie. Dále se zabývá specifiky v péči o
        dětské pacienty a v neposlední řadě informuje sestry o tom, jak mohou co
        nejlépe pracovat o své duševní zdraví a bránit se pracovnímu stresu.
      </p>
      <form @submit.prevent="getSignature">
        <v-text-field
          v-model="stream.userName"
          single-line
          color="blue"
          filled
          :error-messages="
            showErrorMessage
              ? 'Pro vstup do webináře je nutné vyplnit Vaše jméno'
              : ''
          "
          :label="'Vaše jméno'"
        ></v-text-field>
        <button type="submit" @click="getSignature">
          Připojit se na webinář
        </button>
      </form>
    </div>
  </main>
</template>

<script>
export default {
  name: "HelloWorld",
  created() {
    this.ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.0/lib", "/av");
    this.ZoomMtg.preLoadWasm();
    this.ZoomMtg.prepareJssdk();
  },
  mounted() {
    this.ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
      console.log("inMeetingServiceListener onUserJoin", data);
    });
  },
  data() {
    return {
      stream: {
        apiKey: null,
        leaveUrl: "https://jetovkrvi.cz/online-prenos",
        meetingNumber: 83830383185,
        role: 0,
        signature: null,
        userName: "",
      },
      showErrorMessage: false,
    };
  },
  methods: {
    async getSignature() {
      if (this.stream.userName.length > 0) {
        const meetinngId = {
          stream: {
            meeting_id: this.stream.meetingNumber,
          },
        };
        try {
          let response = null;

          ({
            data: { stream: response },
          } = await this.$axios.post(
            "https://jetovkrvi.cz/api/v1/zoom/stream",
            meetinngId,
            {
              headers: {
                "Api-Key": "fMaQiop8zkdRehs4",
              },
            }
          ));
          this.stream.apiKey = response.key;
          this.stream.signature = response.signature;
          this.startMeeting();
        } catch {
          console.log("error");
        }
      } else {
        this.showErrorMessage = true;
      }
    },
    startMeeting() {
      document.getElementById("zmmtg-root").style.display = "block";

      this.ZoomMtg.init({
        leaveUrl: this.stream.leaveUrl,
        success: (success) => {
          console.log(success);
          this.ZoomMtg.join({
            meetingNumber: this.stream.meetingNumber,
            userName: this.stream.userName,
            signature: this.stream.signature,
            apiKey: this.stream.apiKey,
            userEmail: 'tiep.tme@gmail.com',
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
  max-width: 45rem;
  margin: auto;
  text-align: center;
}

::v-deep .v-input input:focus {
  outline: none !important;
}

::v-deep .v-input input {
  outline: none !important;
}

p {
  margin-bottom: 3rem;
}

.icon {
  max-width: 1.5rem;
}

main button {
  margin-top: 20px;
  background-color: #f0d3d3;
  color: #982525;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 5rem;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #f1b3b3;
}

.btn-back {
  text-align: left;
  margin-top: 5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.webinar-card {
  border: 1px solid gainsboro;
  padding: 2rem;
  border-radius: 0.5rem;
  margin: auto;
}
</style>
