<template>
  <div data-aos-id="exercises" id="exercises" class="inner">
    <div id="exercises-inner"><h1>Série cviků</h1></div>
    <v-card flat>
      <v-tabs v-model="tab" show-arrows>
        <v-tab  v-for=" video in videos" :key="video.name">{{video.name}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="video in videos" :key="video.name">
          <v-card flat>
            <v-card-text>
              <div class="video-wrapper">
                <div class="video-name">{{ video.name }}</div>
                <div>
                  <video
                    data-aos="fade-in"
                    data-aos-duration="5000"
                    width="100%"
                    height="auto"
                    controls
                  >
                    <source :src="video.url" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export default {
  name: "Records",
  mounted() {
    document.getElementById("fifth-webinar").click();
  },
  data() {
    return {
      videos: [
        {
          name: "Aktivní sed",
          url: "https://general-storage.b-cdn.net/1_aktivni%20sed.mp4",
        },
        {
          name: "Autorelaxace ručníkem",
          url:
            "https://general-storage.b-cdn.net/2_autorelaxace%20rucnikem.mp4",
        },
        {
          name: "Dech",
          url: "https://general-storage.b-cdn.net/3_dech.mp4",
        },
        {
          name: "Mobilita hrudníku",
          url: "https://general-storage.b-cdn.net/4_mobilita%20hrudniku.mp4",
        },
        {
          name: "Masáž obličeje",
          url: "https://general-storage.b-cdn.net/5_masaz%20obliceje.mp4",
        },
        {
          name: "Práce s horní končetinou",
          url:
            "https://general-storage.b-cdn.net/6_prace%20s%20horni%20koncetinou.mp4",
        },
        {
          name: "Aktivní stoj",
          url: "https://general-storage.b-cdn.net/7_aktivni%20stoj.mp4",
        },
        {
          name: "Relaxace na zemi",
          url: "https://general-storage.b-cdn.net/8_relaxace%20na%20zemi.mp4",
        },
      ],
      tab: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/scss/variables" as *;

#exercises {
  padding-bottom: 10rem;
  padding-top: 4rem;

  .button-wrapper {
    margin-top: 2rem;

    button {
      margin-right: 2rem;
    }
  }

  .video-wrapper {
    margin-top: 2rem;

    video {
      outline: none;
      cursor: pointer;
    }

    .video-name {
        font-size: 1.7em;
        margin-bottom: 1.5rem;
    }

    .video-wrapper_text {
      h1 {
        margin-top: 0;
      }

      .authors {
        margin-bottom: 1rem;
        font-size: 1.3rem;
        font-family: ChampagneThick;
        color: rgb(44, 44, 44);
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .video-wrapper {
    video {
      margin-right: 2rem;
    }
  }
}
@media screen and (max-width: $md) {
  #records {
    background: $secondary;
    padding: 1rem;
    padding-top: 3rem;
    margin: 0 -1rem;

    .v-card {
      background: $secondary;
    }

    ::v-deep .theme--light.v-tabs > .v-tabs-bar {
      background-color: $secondary;
    }
    .video-wrapper {
      flex-direction: column;
      margin-top: 0;

      video {
        width: 100%;
        margin: 0;
      }

      .video-wrapper_text {
        max-width: 100%;
        margin-top: 2rem;
      }
    }
  }
}
</style>
