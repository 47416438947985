<template>
  <div id="schedule">
    <div class="bg" />
    <div id="schledule-inner" class="inner">
      <h1 id="schledule-header">PROGRAM</h1>
      <div class="schedule-table">
        <div class="schedule-table__date">
          <div class="schedule-table__date-wrapper">
            <div class="schedule-table__icon">
              <drop-icon :hover="buttonIndex === 1 ? hover : false" />
            </div>
            <div class="schedule-table__text">
              15. 4. 2021 od 18:00<br />
              on-line <br />
              <v-btn
                @mouseover="hoverOverbutton(1)"
                @mouseleave="hover = false"
                @click="scrollTo('records', 'first-webinar')"
                depressed
                rounded
                >záznam</v-btn
              >
            </div>
          </div>
        </div>
        <div class="schedule-table__topic">
          <strong @click="scrollToPerson('Salaj')">MUDr. Peter Salaj </strong> –
          Hemofilie, současný stav a výzvy do budoucna
          <br />
          <strong @click="scrollToPerson('Adamovska')">Zuzana Adamovská</strong>
          – Péče o pacienty s hemofilií v dětském věku
          <br />
          <strong @click="scrollToPerson('Hoschl')">
            prof. MUDr. Cyril Höschl, DrSc., FRCPsych.</strong
          >
          – Pracovní distres a jak se mu bránit
        </div>
      </div>

      <div class="schedule-table">
        <div class="schedule-table__date">
          <div class="schedule-table__date-wrapper">
            <div class="schedule-table__icon">
              <drop-icon :hover="buttonIndex === 2 ? hover : false" />
            </div>
            <div class="schedule-table__text">
              13. 5. 2021 od 18:00 <br />
              on-line <br />
              <v-btn
                @mouseover="hoverOverbutton(2)"
                @mouseleave="hover = false"
                @click="scrollTo('records', 'second-webinar')"
                depressed
                rounded
                >záznam</v-btn
              >
            </div>
          </div>
        </div>
        <div class="schedule-table__topic">
          <strong @click="scrollToPerson('Hrdlickova')"
            >MUDr. Radomíra Hrdličková
          </strong>
          – Proč vyšetřujeme hemofilické klouby ultrazvukem
          <br />
          <strong @click="scrollToPerson('Cepelakova')"
            >Veronika Čepeláková
          </strong>
          – Domácí léčba u dětí s vrozeným krvácivým onemocněním
          <br />
          <strong @click="scrollToPerson('Engelova')"> Mirka Engelová</strong>
          – Minuta času pro relaxaci
        </div>
      </div>

      <div class="schedule-table">
        <div class="schedule-table__date">
          <div class="schedule-table__date-wrapper">
            <div class="schedule-table__icon">
              <drop-icon :hover="buttonIndex === 3 ? hover : false" />
            </div>
            <div class="schedule-table__text">
              17. 6. 2021 od 18:00 <br />
              on-line <br />
              <v-btn
                @mouseover="hoverOverbutton(3)"
                @mouseleave="hover = false"
                depressed
                rounded
                @click="scrollTo('records', 'third-webinar')"
                >záznam</v-btn
              >
            </div>
          </div>
        </div>
        <div class="schedule-table__topic">
          <strong @click="scrollToPerson('Kohlerova')"
            >MUDr. Köhlerová Světlana
          </strong>
          – Personalizovaná léčba pacienta s hemofilií
          <br />
          <strong @click="scrollToPerson('Voksayova')"
            >Bc. Kateřina Vokšayová, DiS
          </strong>
          – Zkušenosti s registrem ČNHP
          <br />
          <strong @click="scrollToPerson('Janackova')">
            doc. PhDr. Dr. phil. Laura Janáčková, CSc.</strong
          >
          – Štěstí z pohledu pozitivní psychologie
        </div>
      </div>

      <div class="schedule-table">
        <div class="schedule-table__date">
          <div class="schedule-table__date-wrapper">
            <div class="schedule-table__icon">
              <drop-icon :hover="buttonIndex === 4 ? hover : false" />
            </div>
            <div class="schedule-table__text">
              23. 9. od 18:00 <br />
              on-line <br />
              <v-btn
                @mouseover="hoverOverbutton(4)"
                @mouseleave="hover = false"
                @click="scrollTo('records', 'fourth-webinar')"
                depressed
                rounded
                >záznam</v-btn
              >
            </div>
          </div>
        </div>
        <div class="schedule-table__topic">
          <strong @click="scrollToPerson('Pohlreichova')"
            >prim. MUDr. Viera Pohlreichová
          </strong>
          – Morbus von Willebrand – nejčastější vrozená krvácivá nemoc
          <br />
          <strong @click="scrollToPerson('Altmanova')"
            >Kateřina Altmanová
          </strong>
          <span class="strong-and">&</span>
          <strong @click="scrollToPerson('Bohun')"> Martin Bohůn </strong>
          – Podpora a spolupráce pacientských organizací s osobami s poruchou
          krevního srážení, se zdravotníky a širokou laickou veřejností
          <br />
          <strong @click="scrollToPerson('Ptacek')">
            prof. PhDr. Radek Ptáček, Ph.D</strong
          >
          – Péče o duševní zdraví prakticky
        </div>
      </div>

      <div class="schedule-table">
        <div class="schedule-table__date">
          <div class="schedule-table__date-wrapper">
            <div class="schedule-table__icon">
              <drop-icon :hover="buttonIndex === 5 ? hover : false" />
            </div>
            <div class="schedule-table__text">
              13. 1. od 18:00 <br />
              on-line <br />
              <v-btn
                @mouseover="hoverOverbutton(5)"
                @mouseleave="hover = false"
                @click="scrollTo('records', 'fifth-webinar')"
                depressed
                rounded
                >záznam</v-btn
              >
            </div>
          </div>
        </div>
        <div class="schedule-table__topic">
          <strong @click="scrollToPerson('Gumulec')"
            >MUDr. Jaromír Gumulec
          </strong>
          – Získaná hemofilie
          <br />
          <strong @click="scrollToPerson('Malaskova')">Dana Malásková </strong>
          – Použití portu u hemofiliků
          <br />
          <strong @click="scrollToPerson('Sperlova')">
            Ing. Iveta Šperlová</strong
          >
          – Emoční agilita aneb jak chytře na emoce
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropIcon from "./DropIcon.vue";
export default {
  components: { DropIcon },
  name: "Schedule",
  data() {
    return {
      hover: false,
      buttonIndex: 0,
    };
  },
  methods: {
    hoverOverbutton(index) {
      this.hover = true;
      this.buttonIndex = index;
    },
    scrollToPerson(personName) {
      this.$emit("clickOnPerson", personName);

      const element = document.getElementById("lectures");
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    scrollTo(section, tabId) {
      const element = document.getElementById(section);
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });

      const tab = document.getElementById(tabId);
      tab.click();
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/scss/variables.scss" as *;

#schedule {
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 21rem;
  padding-top: 2rem;

  a {
    text-decoration: none;
  }

  .inner {
    max-width: 80rem;
  }

  .bg {
    background: url("../assets/img/wave-bg-1.png");
    position: absolute;
    width: 100%;
    height: 145rem;
    top: 105rem;
    left: 0;
  }

  .inner {
    padding-top: 5rem;
    position: relative;
    z-index: 1;
  }

  .schedule-table {
    display: flex;
    margin-bottom: 5rem;
    background: white;
    border-radius: 0.5rem;
    padding: 2rem;

    .schedule-table__date {
      display: flex;
      border-right: 1px solid $primary;
      padding-right: 3rem;
      width: 28%;
      align-items: center;

      .schedule-table__date-wrapper {
        display: flex;
        align-items: flex-start;
      }

      .schedule-table__text {
        color: $primary;
        font-family: ChampagneThick;

        button {
          margin-top: 1.5rem;
        }
      }

      .schedule-table__icon {
        max-width: 5rem;
        margin-right: 1rem;
        max-height: 8rem;
        img {
          width: 100%;
        }
      }
    }

    .schedule-table__topic {
      padding: 0 2rem;
      line-height: 3rem;
      width: 70%;
      font-family: Champagne;

      .working-on-img {
        max-width: 15rem;
      }

      .strong-and {
        color: $primary;
        font-weight: bold;
      }

      strong {
        color: $primary;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: $lg) {
  #schedule {
    .bg {
      background: $secondary;
      height: 120rem;
      top: auto;
    }
  }
}

@media screen and (max-width: $md) {
  #schedule {
    margin-top: 0;

    .bg {
      display: none;
    }

    #schledule-inner {
      background: $secondary;
      padding: 2rem 1rem;
      margin: 0 -1rem;
    }

    .schedule-table {
      flex-direction: column;
      .schedule-table__date {
        border-right: none;
        width: 100%;
        margin-bottom: 2rem;
        padding-right: 0;
        padding: 1rem;
      }

      .schedule-table__topic {
        width: 100%;
      }
    }
  }
}
</style>
