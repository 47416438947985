<template>
  <div class="waves">
    <section>
      <div class="wave wave1"></div>
      <div class="wave wave2"></div>
      <div class="wave wave3"></div>
      <!-- <div class="wave wave4"></div> -->
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.waves {
  position: absolute;
  width: 100%;
  left: 0;
  top: 49rem;
}
section {
  position: relative;
  width: 100%;
  height: 21rem;
  overflow: hidden;

  .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 21rem;
    background: url("../assets/img/wave.png");
    background-size: 1500px;
  }

  .wave1 {
    animation: animate 4s linear infinite;
    z-index: 1;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
  }

  .wave2 {
    animation: animate2 5s linear infinite;
    z-index: 0.9;
    opacity: 0.5;
    animation-delay: 0s;
    bottom: 0;
  }

  .wave3 {
    animation: animate 5s linear infinite;
    z-index: 0.8;
    opacity: 0.3;
    animation-delay: -2s;
    bottom: 15;
  }

  .wave4 {
    animation: animate 3.5s linear infinite;
    z-index: 0.7;
    opacity: 0.7;
    animation-delay: 0s;
    bottom: 20;
  }

  @keyframes animate {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 1500px;
    }
  }

  @keyframes animate2 {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: -1500px;
    }
  }
}
@media screen and (max-width: 1264px) {
  .waves {
    top: 71rem;
  }
}

@media screen and (max-width: 500px) {
  .waves {
    top: 82rem;
  }
}
</style>